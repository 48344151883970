.App {
  text-align: center;
}

.footer-area .MuiDrawer-paperAnchorBottom {
  overflow-y: 'hidden';
  overflow-x: 'hidden';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.dark-background {
  background-color: #282c34;
}

.primary-background {
  background-color: #90caf9;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.overwrite-disabled {
  color: white !important;
}

.overwrite-disabled label, .overwrite-disabled div textarea, .overwrite-disabled div input {
  color: white !important;
  -webkit-text-fill-color: white !important
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.side-image, .header-image {
  height:30px;
}

.icon-image {
  max-width: 1rem;
  display: inline-block;
  vertical-align: middle;
}

.transaction-icon {
  margin: 0.2rem;
  vertical-align: sub;
}

.cost-cell::before{
  /*content: url('./static/images/matic.png');
  max-width: 1rem;
  display: inline-block;
  vertical-align: middle;*/

}

.side-image {
  margin-left:auto;
  margin-right:auto;
  display:block;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone-container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.drop-aside {
  display: flex; flex-flow: row wrap; margin-top: 16px;
}

.transparent-background {
  background-color:transparent !important;
  box-shadow: none !important;
  background-image: none !important;
}

.react-numeric-input {

}

.react-numeric-input input {
  background-color: transparent;
  border: 1px solid #767676;
  box-shadow: none;
  border-style: none;
  color:white;
}

.react-numeric-input b, .react-numeric-input b i {
  color:white;
}

.MuiAvatar-img {
  border-radius: 100px;
}

.rhap_time {
  color: grey !important;
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(0deg) !important
}

.MuiAccordionSummary-expandIconWrapper {
  transform: rotate(180deg) !important
}

.MuiAccordionSummary-content {
  margin-top:0px !important;
  margin-bottom: 0px !important;
  padding-top:2px !important;
  padding-bottom: 2px !important;
}

.MuiTypography-body1 {
  font-weight: 600 !important;
}

.secondary-links span {
  font-size: .9rem;
}

.white-text {
  color: white !important;
}

.card-avatar {
  position: absolute !important;
  bottom:-0.6rem;
  left: 0.5rem;
}

.error-cell {
  border-left:#d32f2f solid 1px;
  border-right:#d32f2f solid 1px;
}